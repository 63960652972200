export const SELECTED_NEWS = 'SELECTED_NEWS';
export const EMPTY_SELECTED_NEWS = 'EMPTY_SELECTED_NEWS';

export const FETCH_MEALS = 'FETCH_MEALS';
export const CREATE_MEAL = 'CREATE_MEAL';
export const DELETE_MEAL = 'DELETE_MEAL';
export const EDIT_MEAL = 'EDIT_MEAL';

export const SELECT_ITEM_TO_EDIT = 'SELECT_ITEM_TO_EDIT';
export const TOGGLE_EDIT_MODE = 'TOGGLE_EDIT_MODE';

export const FETCH_SOUPS = 'FETCH_SOUPS';
export const EDIT_SOUPS = 'EDIT_SOUPS';

export const CREATE_CARD = 'CREATE_CARD';
export const FETCH_CARDS = 'FETCH_CARDS';
export const DELETE_CARD = 'DELETE_CARD';
export const EDIT_CARD = 'EDIT_CARD';

export const UPDATE_HOURS = 'UPDATE_HOURS';
export const UPDATE_SOUPS = 'UPDATE_SOUPS';
export const UPDATE_NEWS = 'UPDATE_NEWS';
export const UPDATE_CARDS = 'UPDATE_CARDS';
export const UPDATE_MEALS = 'UPDATE_MEALS';
export const UPDATE_TEXTS = 'UPDATE_TEXTS';
export const UPDATE_MEAL_BOX_PRICES = 'UPDATE_MEAL_BOX_PRICES';
export const UPDATE_SOUP_BOX_PRICES = 'UPDATE_SOUP_BOX_PRICES';

export const FETCH_DATA = 'FETCH_DATA';

export const ORDERS_INCREMENT = 'ORDERS_INCREMENT';
export const ORDERS_DECREMENT = 'ORDERS_DECREMENT';

export const UPDATE_ORDER = 'UPDATE_ORDER';
export const SET_TOTAL = 'SET_TOTAL';
export const SET_TOTAL_PRICE = 'SET_TOTAL_PRICE';
export const SAVE_CUSTOMER_INFO = 'SAVE_CUSTOMER_INFO';
export const RESET_ORDERS = 'RESET_ORDERS';

export const FETCH_ORDERS = 'FETCH_ORDERS';
export const CREATE_ORDER = 'CREATE_ORDER';

export const GET_ALL_ORDERS = 'GET_ALL_ORDERS';

export const START_ORDERING = 'START_ORDERING';

export const ORDER_METHOD = 'ORDER_METHOD';

export const UPDATE_ORDERING_STATUS = 'UPDATE_ORDERING_STATUS';
